export const dropdownData = [
  {
    id: 1,
    title: 'Recently Listed',
  },
  {
    id: 2,
    title: 'Recently Created',
  },
  {
    id: 3,
    title: 'Recently Sold',
  },
  {
    id: 4,
    title: 'Ending Soon',
  },
  {
    id: 5,
    title: 'Price Low to High',
  },
  {
    id: 6,
    title: 'Highest last sale',
  },
  {
    id: 7,
    title: 'Most viewed',
  },
  {
    id: 8,
    title: 'Most Favorited',
  },
  {
    id: 9,
    title: 'Price High to Low',
  },
  {
    id: 10,
    title: 'Oldest',
  },
];
